
import React, { useState } from 'react'
import Layout from '../../components/layout'
import styled, { keyframes } from 'styled-components'
import { Container, Typography, Grid, Paper } from '@material-ui/core'
import { Colors } from '../../constants'
import Particles from './_particles'
import Fade from 'react-reveal/Fade';
import Ticker from 'react-ticker'

import WbCloudyIcon from '@material-ui/icons/WbCloudy';
import CreateIcon from '@material-ui/icons/Create';
import WebIcon from '@material-ui/icons/Web';

import PageVisibility from 'react-page-visibility'

const DiscoverImage = require('../../dist/images/designAlt.svg');
const DevelopImage = require('../../dist/images/prototype.svg');
const BugfixImage = require('../../dist/images/build.svg');

export default () => {
    const [pageIsVisible, setPageIsVisible] = useState(true)

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }

    return (
        <Layout padTop={true} title={'Professional Web Development'} description={'Regardless of scale, a well constructed website is paramount towards the success of your online presence. We build all of our websites from the ground up, yielding a pixel perfect product identical to any provided design.'}>
            <Banner>
                <Particles />
                <BannerTextContainer>
                    <div style={{position: 'absolute', bottom: '25px', left: '0', width: '100%'}}>
                        <Container style={{position: 'relative'}}>
                            <div style={{maxWidth: '550px'}}>
                                <BannerTextMain variant={'button'}>Professional Web Development</BannerTextMain>
                            </div>                                
                        </Container>      
                    </div>     
                </BannerTextContainer>
            </Banner>
            <div style={{background: '#F9F9F9'}}>
                <Container style={{paddingTop: '25px', paddingBottom: '25px', textAlign: 'center'}}>
                    <InfoHeader variant={'button'}>
                        Reach your audience effectively
                    </InfoHeader>
                    <InfoBody>
                        Regardless of scale, a well constructed website is paramount towards the success of your online presence.
                        We build all of our websites from the ground up, yielding a pixel perfect product identical to any provided design.
                    </InfoBody>
                </Container>
            </div>
            <PageInformation>
                    <ProcessDiagramContainer>
                        <ProcessDiagramLinesWrapper> 
                            <PageVisibility onChange={handleVisibilityChange}>
                            {
                                pageIsVisible && <Ticker mode={'chain'} speed={10} offset={'100%'} direction={'toRight'}>
                                    {
                                        ({ index }) =>   (                                        
                                            <div style={{whiteSpace: 'nowrap'}}>
                                                <svg height="80" width="25px">
                                                    <g fill="none" stroke="black" stroke-width="4">
                                                        <KeyframesPath d="M5 40 l2150 0" />
                                                    </g> 
                                                </svg>
                                                
                                            </div>                                   
                                        )                                           
                                
                                }
                        
                            </Ticker>
                            }  
                        </PageVisibility>
                                
                        </ProcessDiagramLinesWrapper>

                        
                        <ProcessSection>
                            <ProcessNumber><CreateIcon className={'child'}/></ProcessNumber>
                            <ProcessInformationWrapper>
                                <ProcessInformation>Design</ProcessInformation>
                            </ProcessInformationWrapper>
                        </ProcessSection>
                        <ProcessSection>
                            <ProcessNumber><WebIcon className={'child'}/></ProcessNumber>
                            <ProcessInformationWrapper>
                                <ProcessInformation>Develop</ProcessInformation>
                            </ProcessInformationWrapper>
                        </ProcessSection>
                        <ProcessSection>
                            <ProcessNumber><WbCloudyIcon className={'child'}/></ProcessNumber>
                            <ProcessInformationWrapper>
                                <ProcessInformation>Deploy</ProcessInformation>
                            </ProcessInformationWrapper>
                        </ProcessSection>
                        {/* <ProcessSection>


                            <ProcessNumber><div className={'child'} style={{background: `url(${OneImg}) no-repeat center center`, backgroundSize: 'contain' }} /></ProcessNumber>
                            <ProcessInformationWrapper>
                                <ProcessInformation>Design</ProcessInformation>
                            </ProcessInformationWrapper>
                        </ProcessSection>
                        <ProcessSection>
                            <ProcessNumber><div className={'child'} style={{background: `url(${TwoImg}) no-repeat center center`, backgroundSize: 'contain' }} /></ProcessNumber>
                            <ProcessInformationWrapper>
                                <ProcessInformation>Prototype</ProcessInformation>
                            </ProcessInformationWrapper>
                        </ProcessSection>
                        <ProcessSection>
                            <ProcessNumber><div className={'child'} style={{background: `url(${ThreeImg}) no-repeat center center`, backgroundSize: 'contain' }} /></ProcessNumber>
                            <ProcessInformationWrapper>
                                <ProcessInformation>Deploy</ProcessInformation>
                            </ProcessInformationWrapper>


                        </ProcessSection> */}
                    </ProcessDiagramContainer>
            </PageInformation>
            
            <Separater />

            <Container>
                <Fade top>
                    <Section>
                        <SectionHeader>
                            <SectionImage src={'discover'} />                      
                        </SectionHeader>

                        <SectionBody>
                            <InfoHeader>PROJECT DESIGN</InfoHeader>
                            <Divider />
                            <InfoBody>
                                <InfoBodyItem>Our UI/UX designers will work with you to find the style that best suits your business or product.</InfoBodyItem>
                                <ul style={{paddingLeft: '25px'}}>
                                    <li>
                                        <InfoBodyItem>
                                            Anticipate mockups for every product specification, including affiliated color palettes and responsive designs.
                                        </InfoBodyItem>
                                    </li>
                                    <li>
                                        <InfoBodyItem>
                                            Final product designs feature a reusable template-like approach to increase your website's future expansion potential.
                                        </InfoBodyItem>
                                    </li>
                                </ul>  
                            </InfoBody>
                        </SectionBody>
                    </Section>
                    <Section>
                        <SectionHeader>
                            <SectionImage src={'develop'} />                      
                        </SectionHeader>

                        <SectionBody>
                        <InfoHeader>WEBSITE DEVELOPMENT</InfoHeader>
                        <Divider />
                            <InfoBody>
                                <InfoBodyItem>Our dedicated team of developers will swiftly turn your provided content into a responsive website, built from the ground up.</InfoBodyItem>
                                <ul style={{paddingLeft: '25px'}}>
                                    <li>
                                        <InfoBodyItem>
                                            During development, a demo site link will be provided to allow continuous feedback from all stakeholders.
                                        </InfoBodyItem>
                                    </li>
                                    <li>
                                        <InfoBodyItem>
                                            Rely on streamlined communication between your team and ours, ensuring your product is pixel perfect and 
                                            functionally optimized. 
                                        </InfoBodyItem>
                                    </li>
                                </ul>  
                            </InfoBody>
                        </SectionBody>
                        
                    </Section>
                    <Section>
                        <SectionHeader>
                            <SectionImage src={'bugfix'} />                      
                        </SectionHeader>

                        <SectionBody>
                            <InfoHeader>SCALED DEPLOYMENT</InfoHeader>
                            <Divider />
                            <InfoBody>
                            <InfoBodyItem>Our cloud platform engineers will guide you through your deployment options.</InfoBodyItem>
                                <ul style={{paddingLeft: '25px'}}>
                                    <li>
                                        <InfoBodyItem>
                                            We will deploy your site to any server or cloud platform, without scale constriction.
                                        </InfoBodyItem>
                                    </li>
                                    <li>
                                        <InfoBodyItem>
                                            We offer containerized microservice support to ensure core functionality is portable and 
                                            consistent on any device type.
                                        </InfoBodyItem>
                                    </li>
                                    <li>
                                        <InfoBodyItem>    
                                            Ensure your site is always up to date, through a custom, automated deploy pipeline.
                                        </InfoBodyItem>
                                    </li>
                                </ul>                            
                            </InfoBody>
                        </SectionBody>
                    </Section>
                </Fade>
            </Container>
        </Layout>
    )
}

const Divider = styled.div`
    margin-top: 5px;
    width: 40px;
    height: 3px;
    background-color: ${Colors.auxidusBlue};
    opacity: 0.7;
`;
const Section = styled.div`
    position: relative;
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${props => props.type === 'alt' ? 'calc(100% - 300px) 300px' : '300px calc(100% - 300px)'};
    grid-template-gap: 1%;
    min-height: 300px;
    height: 100%;
    @media only screen and (max-width: 800px) {
        min-height: 0px;
        display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    }
    margin: 50px auto;
`;
const SectionBody = styled.div`
    background-color: #FFFFFF;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    flex-direction: column;
    margin-left: 50px;
    text-align: left;
    padding-left: ${props => props.type === 'alt' ? '0' : '50px'};
    padding-right: ${props => props.type === 'alt' ? '50px' : '0'};
    @media only screen and (max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
    }
`;
const SectionImage = styled.div`
    background: ${props => props.src === 'discover' ? `url(${DiscoverImage}) no-repeat center center` : props.src === 'develop' ? `url(${DevelopImage}) no-repeat center center` : `url(${BugfixImage}) no-repeat center center`}; 
    background-size: contain;
    min-height: 300px;
    height: 100%;
    width: 300px;
    position: relative;
    margin: auto;
    @media only screen and (max-width: 800px) {
        height: 0;
        width: 0;
        display: none;
    }
`;
const SectionHeader = styled.div`
    // border: 1px solid ${Colors.auxidusBlue};
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
`;

const InfoHeader = styled(Typography)`
    font-size: calc(15px + 4 * ((100vw - 185px) / 640));
    font-weight: bold;
    color: black;
`;
const InfoBody = styled(Typography)`
    margin-top: 25px;
    font-size: calc(13px + 2 * ((100vw - 185px) / 640));
    color: ${Colors.textSecondaryGray};
`;

const InfoBodyItem = styled.div`
    padding: 5px;
`;

const Separater = styled.div`
    background-color: #fefefe;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='12' viewBox='0 0 40 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.172L6.172 0h5.656L0 11.828V6.172zm40 5.656L28.172 0h5.656L40 6.172v5.656zM6.172 12l12-12h3.656l12 12h-5.656L20 3.828 11.828 12H6.172zm12 0L20 10.172 21.828 12h-3.656z' fill='%23666666' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: 10px;
    width: 100%;
`;
const ProcessInformation = styled(Typography)`
    font-size: calc(13px + 2 * ((100vw - 185px) / 640));
    color: ${Colors.auxidusBlue};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em
`
const ProcessInformationWrapper = styled.div`
    width: calc(100% - 30px);
    overflow: normal;
    overflow-wrap: anywhere;
    margin-top: 25px;
    text-align: center;
    height: 100%;
    padding: auto 10px;
`;
const KeyframesPath = styled.path`
    stroke: ${Colors.auxidusBlue};
`;
const ProcessDiagramLinesWrapper = styled.div`
    position: absolute;
    width: 65%;
    left: 19%;
    overflow: hidden;
    place-self: center;
    @media only screen and (min-width: 0px) {
        padding-bottom: 60px;
    }
    @media only screen and (min-width: 338px) {
        padding-bottom: 45px;
    }
    @media only screen and (min-width: 1024px) {
        padding-bottom: 45.5px;
    }
    @media only screen and (min-width: 1170px) {
        padding-bottom: 45px;
    }
    @media only screen and (min-width: 1752px) {
        padding-bottom: 50px;
    }

`;
const ProcessNumber = styled.div`
    background: ${Colors.auxidusBlue};
    color: #f3f3f3;
    display: -webkit-box;
    height: calc(22px + 7 * ((100vw - 185px) / 640));
    width: calc(22px + 7 * ((100vw - 185px) / 640));
    padding: 1.35vw;
    flex: none;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    .child {
        height: calc(14px + 7 * ((100vw - 185px) / 640));
        width: calc(14px + 5 * ((100vw - 185px) / 640));
        
        position: absolute;
        opacity: 0.8;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        -moz-transform: translate(-50%, -50%) rotate(-45deg);
        -o-transform: translate(-50%, -50%) rotate(-45deg);
        -ms-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;
const ProcessDiagramContainer = styled(Container)`
    width: 100%;
    padding: 0;
    position: relative;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 33% 33% 33%;
`;
const ProcessSection = styled.div`
    width: 100%;
    height: 100%;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Banner = styled.div`
    width: 100%;
    height: 20vmax;
    position: relative;
    background-color: #F9F9F9;
    border-bottom: 2px solid ${Colors.auxidusBlue};
`;
const BannerTextContainer = styled.div`
    position: absolute;
    left: 0; right: 0;
    bottom: 0;
    width: 100%;
    height: 250px;
`;
const BannerTextMain = styled(Typography)`
    font-size: calc(22px + 4 * ((100vw - 185px) / 380));
    font-weight: bold;
    color: ${Colors.backgroundBlueLighter};
    line-height: calc(22px + 6 * ((100vw - 185px) / 380));
`;
const PageInformation = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    color: lightgrey;
    position: relative;
    padding: 25px 0px;
`;